import * as React from "react"

import AnimationWrapper from "./animationWrapper"

import "./dataTable.css"

const DataTable = ( { data, location } ) => {

  return (

    <div className={`data-table ${location}-data-table`}>

      <AnimationWrapper className="table-container">
        <table>
          <tbody>
            {data.map((row, key) => (
              <tr key={key}>
                <th>{row.property}</th>
                <td>{row.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </AnimationWrapper>

    </div>
  )
}

export default DataTable