import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import PropTypes from "prop-types"

const LightboxImage = ( { image, alt, objectFit, className } ) => {

  return (
    <SimpleReactLightbox>
      <SRLWrapper>
        <a href={image.publicURL}>
          <GatsbyImage
            className={className}
            image={image.childImageSharp.gatsbyImageData}
            alt={alt}
            objectFit={objectFit}
          />
        </a>
      </SRLWrapper>
    </SimpleReactLightbox>
  )
}

LightboxImage.propTypes = {
  alt: PropTypes.string,
  objectFit: PropTypes.string,
  className: PropTypes.string,
};

LightboxImage.defaultProps = {
  alt: '',
  objectFit: 'cover',
  className: '',
};

export default LightboxImage