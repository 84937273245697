/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Do sierpnia 2023 r. trwały prace nad realizacją inwestycji pn. „Zadanie awaryjne - wzmocnienie podłoża gruntowego na drodze ekspresowej S5 w obrębie węzła Bydgoszcz Błonie”."), "\n", React.createElement(_components.p, null, "W ramach zadania wykonane zostały następujące prace budowlane"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Naprawa nawierzchni, w tym frezowanie, rozbiórka"), "\n", React.createElement(_components.li, null, "Wbudowanie podbudowy"), "\n", React.createElement(_components.li, null, "Położenie nowej nawierzchni bitumicznej"), "\n"), "\n", React.createElement(_components.p, null, "Prace budowalne trwały ok. 3 miesięcy."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
